// COMMON DEFAULTS
export const onboardingRoute = "/onboarding";
export const authRoute = "/auth";
export const welcomeRoute = "/welcome";

export const publicURLs = [
	"/404",
	"/email",
	"/privacy-policy",
	"/terms-and-conditions",
	"/all-methodologies",
	"/registry",
	"/upcoming-methodologies",
] as const;

// ROLE SPECIFIC ROUTES
const commonRoutes = [welcomeRoute] as const;

export const projectDeveloperRouteRoots = ["/project-developer", "/reviewer", ...commonRoutes] as const;
export const reviewerRouteRoots = ["/reviewer", "/project-developer", ...commonRoutes] as const;

export const level1RouteRoots = [...commonRoutes, "/methodologies"];
export const level2RouteRoots = [
	...commonRoutes,
	...level1RouteRoots,
	"/projects/my-projects",
	"/projects/new-project-proposal",
	"/credits",
	"/inventory",
];
export const level3RouteRoots = [...commonRoutes, ...level2RouteRoots, "/projects"];

export const adminRouteRoots = ["/admin", ...commonRoutes] as const;

// ROLE SPECIFIC DEFAULT ROUTES
export const methodologyCuratorDefaultRoute = "/methodologies/my-methodologies";
export const reviewerDefaultRoute = "/methodologies/my-reviewpanels";
export const validatorDefaultRoute = "/projects/projects-in-validation";
export const buyerDefaultRoute = "/projects/my-projects";
export const adminDefaultRoute = "/admin/tools";

export const regsitryRoute = "/registry";
